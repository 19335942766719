.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.search-box {
  width: 100%;
  height: 0.64rem;
  border-radius: 0.08rem;
  overflow: hidden;
}
.search-box .h64 {
  position: relative;
  height: 0.64rem;
}
.search-box .search-btn {
  height: 0.64rem;
  line-height: 0.64rem;
  padding: 0 0.3rem;
  color: #fff;
  background: var(--main-color);
  /*border: 1px solid var(--main-color);*/
  position: absolute;
  right: 0;
  top: 0rem;
  border-bottom-right-radius: 0.08rem;
  border-top-right-radius: 0.08rem;
  z-index: 5;
}
.search-box .search-btnClose {
  height: 0.64rem;
  line-height: 0.64rem;
  padding: 0 0.3rem;
  color: #fff;
  background: var(--main-color);
  /*border: 1px solid var(--main-color);*/
  position: absolute;
  right: 0.3rem;
  top: 1rem;
  border-bottom-right-radius: 0.08rem;
  border-top-right-radius: 0.08rem;
  z-index: 5;
}
.search-box .input-border {
  border: 1px solid #e1e1e1;
}
.search-box .search-input {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 0.4rem;
  /*padding-right: 0.3rem;*/
  z-index: 1;
  background-color: #FFFFFF;
}
.search-box .search-input .placeholder {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.4rem;
  font-size: 0.28rem;
  color: #ccc;
  line-height: 0.44rem;
}
.search-box .search-input input {
  flex: 1;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.44rem;
}
.search-box .search-input input::-webkit-input-placeholder {
  font-size: 0.28rem;
  color: #ccc;
}
.search-box .search-input .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.3rem;
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  z-index: 2;
}
.search-box .search-input .icon-search {
  background-image: url('../../assets/icon_home_search@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .search-box .search-input .icon-search {
    background-image: url('../../assets/icon_home_search@3x.png');
  }
}
