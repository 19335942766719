
@import "../../common/less/variable.less";

.category {
  height: 100%;
  background: #fff;
  padding-top: 1rem;
  padding-bottom: 0.96rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .top-box {
    width: 100%;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    position: fixed;
    top: 0;

    .search-content {
      width: 6.9rem;
      height: 0.64rem;
    }
  }

  .content {
    padding-top: 0.26rem;
    box-sizing: border-box;
    position: static;

    .content-title {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 3.22rem;
      height: 0.54rem;
      border-radius: 0.54rem;
      /*background: rgba(108, 87, 255, 0.1);*/
      margin: 0 auto;
      padding: 0 0.25rem;
      box-sizing: border-box;

      .dian {
        width: 2px;
        height: 2px;
        background: #333333;
        border-radius: 2px;
      }

      .line {
        width: 0.56rem;
        height: 1px;
        background: #333333;
      }

      .text {
        font-size: 0.24rem;
        color: #333333;
      }
    }

    .goods-content {
      display: flex;
      flex-wrap: wrap;

      .goods-item {
        width: 1.6rem;
        margin-top: 0.2rem;
        margin-right: 0.2rem;

        .goods-img {
          width: 1.6rem;
          height: 1.6rem;
          margin-bottom: 5px;

          .img {
            width: 1.6rem;
            height: 1.6rem;
            // border-radius: 4px;
          }
        }

        .goods-name {
          text-align: center;
          font-size: 0.24rem;
          color: #666666;
          /*border: 1px solid #666666;*/
          border-radius: 0.5rem;
          height: 0.6rem;
          line-height: 0.6rem;
          background: rgba(108, 87, 255, 0.1);
        }
      }

      .goods-item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
