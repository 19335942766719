.border-none[data-v-726b6ff0]:before {
  display: none;
}
.border-none[data-v-726b6ff0]:after {
  display: none;
}
.flex-fix[data-v-726b6ff0] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-726b6ff0]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-726b6ff0] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-726b6ff0] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-726b6ff0] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-726b6ff0] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-726b6ff0] {
  *zoom: 1;
}
.clear-fix[data-v-726b6ff0]:before,
.clear-fix[data-v-726b6ff0]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.search-box[data-v-726b6ff0] {
  width: 100%;
  height: 0.64rem;
  border-radius: 0.08rem;
  overflow: hidden;
}
.search-box .h64[data-v-726b6ff0] {
  position: relative;
  height: 0.64rem;
}
.search-box .search-btn[data-v-726b6ff0] {
  height: 0.64rem;
  line-height: 0.64rem;
  padding: 0 0.3rem;
  color: #fff;
  background: var(--main-color);
  /*border: 1px solid var(--main-color);*/
  position: absolute;
  right: 0;
  top: 0rem;
  border-bottom-right-radius: 0.08rem;
  border-top-right-radius: 0.08rem;
  z-index: 5;
}
.search-box .search-btnClose[data-v-726b6ff0] {
  height: 0.64rem;
  line-height: 0.64rem;
  padding: 0 0.3rem;
  color: #fff;
  background: var(--main-color);
  /*border: 1px solid var(--main-color);*/
  position: absolute;
  right: 0.3rem;
  top: 1rem;
  border-bottom-right-radius: 0.08rem;
  border-top-right-radius: 0.08rem;
  z-index: 5;
}
.search-box .input-border[data-v-726b6ff0] {
  border: 1px solid #e1e1e1;
}
.search-box .search-input[data-v-726b6ff0] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  padding-left: 0.4rem;
  /*padding-right: 0.3rem;*/
  z-index: 1;
  background-color: #FFFFFF;
}
.search-box .search-input .placeholder[data-v-726b6ff0] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0.4rem;
  font-size: 0.28rem;
  color: #ccc;
  line-height: 0.44rem;
}
.search-box .search-input input[data-v-726b6ff0] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.44rem;
}
.search-box .search-input input[data-v-726b6ff0]::-webkit-input-placeholder {
  font-size: 0.28rem;
  color: #ccc;
}
.search-box .search-input .icon[data-v-726b6ff0] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0.3rem;
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  z-index: 2;
}
.search-box .search-input .icon-search[data-v-726b6ff0] {
  background-image: url('../../assets/icon_home_search@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.search-box .search-input .icon-search[data-v-726b6ff0] {
    background-image: url('../../assets/icon_home_search@3x.png');
}
}
